import {createSlice} from '@reduxjs/toolkit';

export const AdminSlice=createSlice({
    name:"admin",
    initialState:{
        pages:"default",
    },
    reducers:{
        changePages:(state,action)=>{
            state.pages=action.payload;
        }
    }
})

export const {changePages}=AdminSlice.actions;
export default AdminSlice.reducer;