import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { changePages } from "../../redux/Admin.Slice";


function RightPanel(){
    const dispatch=useDispatch()
    return(
        <div className="container">
            <div className="">
            <div className='profile-menu shadow  rounded'>
                <div className="dh" onClick={(e)=>dispatch(changePages("default"))}><h6>Dashboard</h6></div>
                <ul className='navbar-nav'>
                    <li className="nav-item " onClick={(e)=>{
                             e.preventDefault()
                        dispatch(changePages("users"))}}><a className='nav-link di1' href="" > Users</a> </li>
                    <li className="nav-item " onClick={(e)=>{
                        e.preventDefault()
                        dispatch(changePages("draw"))}} ><a className='nav-link  di1' href="">Create Draw</a>  </li>
                          <li className="nav-item " onClick={(e)=>{
                             e.preventDefault()
                        dispatch(changePages("start_draw"))}}><a className='nav-link di1' href="" > Start Draw</a> </li>

                 {/*    <li className="nav-item "><a className='nav-link ' href="">Favorite Lotteries</a>  </li>
                    <li className="nav-item " ><a className='nav-link ' href="/">Log Out</a>  </li> */}
                </ul>

            </div>  

            </div>

            </div>
    )
}
export default RightPanel
