import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Icon } from 'semantic-ui-react'
import api from "../../conf/api.json"
function Users({ id }) {

    const API = `${api.api_base+"/api/admin/users"}`;
    const [data, setData] = useState()
    const [data1, setData1] = useState()

    useEffect(() => {
        axios.get(API).then(res => { setData(res.data); })

    }, [])

    return (
        <>

            {data?.map((res) => (
                <div key={data.userId} className="col-sm-3   usr">
                    <Card>
                        <img className="" src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>{res?.name}</Card.Header>
                            <Card.Meta>
                                <span className='date'>Joined in : {res?.registerDate?.slice(0, 24)}</span>
                            </Card.Meta>
                            <Card.Description>

                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra className="scap">
                            <label> Tickets:</label><div className="row ">
                            {res?.userTickets.map(item => 
                                <div className='col-sm-3 bgit '><span>{item?.ticketNo}</span></div>
                            )}</div>
                        </Card.Content>
                    </Card>
                </div>))
            }
        </>
    )
}

export default Users