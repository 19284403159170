import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import RightPanel from '../components/Navbar/RightPanel';
import { useSelector, useDispatch, useStore } from 'react-redux'
import HomeDashboard from '../components/Home/HomeDashboard';
import Users from '../components/User/Users';
import Draw from '../components/Draw/Draw'
import StartDraw from '../components/start_draw/StartDraw';


function Home() {

  const status=useSelector(res=>res.admin.pages)
console.log(status)
  
  return (
 <><Navbar/>   
 <div className=''>
<div className="row col-sm-12">
  <div className="col-sm-3">
    <RightPanel/>
  </div>
  <div className="col-sm-9 row">
    { status==="default"?<HomeDashboard/>:status==="users"?<Users/>:status==="draw"?<Draw/>:status==="start_draw"?<StartDraw/>:null}
  </div>
</div>

  </div>
  </>
  )
}

export default Home