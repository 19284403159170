import axios from 'axios'
import React, { useState } from 'react'
import api from "../../conf/api.json"
function Draw() {

  const [drawName, setDrawName] = useState();
  const [drawDateEnd, setDrawDateEnd] = useState();
  const [drawDateStart, setDrawDateStart] = useState();
  const [award, setAward] = useState();
  const API = `${api.api_base+"/api/draw"}`;
  const [data, setData] = useState();
  const [ticketNumber, setTicketNumber] = useState();

  async function createDraw() {

    await axios.post(API, {

      drawName: drawName?.trim(),
      drawDateStart: drawDateStart,
      drawDateEnd: drawDateEnd,
      award: award,
      ticketNumber: ticketNumber

    }).then(res => {
      window.alert(res.data.message);

    });


  };

  return (
    <div className='container  '>
      <div className="col-sm-12 ">
        <h6>Create Draw</h6>

        <div class="mb-1">
          <label for="formGroupExampleInput" class="form-label">Raffle Name</label>
          <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Draw Name" onChange={(e) => { setDrawName(e.target.value) }} />
        </div>
        <div class="mb-1">
          <label for="formGroupExampleInput2" class="form-label">Raffle Start Date</label>
          <input type="datetime-local" class="form-control" id="formGroupExampleInput2" placeholder="Raffle Start Date" onChange={(e) => setDrawDateStart(e.target.value)} />
        </div>
        <div class="mb-1">
          <label for="formGroupExampleInput2" class="form-label">Raffle End Date</label>
          <input type="datetime-local" class="form-control" id="formGroupExampleInput2" placeholder="Raffle End Date" onChange={(e) => setDrawDateEnd(e.target.value)} />
        </div>
        <div class="mb-1">
          <label for="formGroupExampleInput2" class="form-label">Awards</label>
          <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Awards" onChange={(e) => setAward(e.target.value)} />
        </div>
        <div class="mb-1">
          <label for="formGroupExampleInput2" class="form-label">Number of Tickets to be Created</label>
          <input type="number" class="form-control" id="formGroupExampleInput2" placeholder="Number of Tickets to be Created" onChange={(e) => setTicketNumber(e.target.value)} />
        </div>

        <button className='btn btn-primary' onClick={() => createDraw()}>Create</button>


        {
          data?.map(res => <div className='container'>
            <div className='row'>
              <div className='draw'>
                <h6 >{res.drawName}</h6>
              </div>
            </div>
          </div>)
        }

      </div>


    </div>
  )
}

export default Draw