import React from 'react'
import {BsBell} from 'react-icons/bs'
import {AiOutlineUnorderedList} from 'react-icons/ai'
import {TiMessages} from 'react-icons/ti'
import {VscAccount} from 'react-icons/vsc'


function Navbar() {
  return (

    <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link " aria-current="page" href="#"><BsBell className='ic'/></a>
          </li>
          <li class="nav-item ms-2">
            <a class="nav-link" href="#"><AiOutlineUnorderedList className='ic'/></a>
          </li>
      
          <li class="nav-item ms-2">
            <a class="nav-link"><TiMessages className='ic'/></a>
          </li>
          <li className='nav-item ms-2'>
            <a href="" className='nav-link'><VscAccount className='ic'/></a>
          </li>
        </ul>
        
      </div>
    </div>
  </nav>
  )
}

export default Navbar