import axios from 'axios'
import React, { useState } from 'react'
import api from "../../conf/api.json"
function StartDraw() {

  const [drawName, setDrawName] = useState();
  const [drawDateEnd, setDrawDateEnd] = useState();
  const [drawDateStart, setDrawDateStart] = useState();
  const [award, setAward] = useState();
  const API = `${api.api_base+"/api/draw/start"}`;
  const [data, setData] = useState();
  const [ticketNumber, setTicketNumber] = useState();
  
  async function createDraw() {

    await axios.post(API, {

      drawName: drawName.trim(),


    }).then(res => {
     setData(res?.data)

    });


  };

  return (
    <div className='container  '>
      <div className="col-sm-12 ">
        <h6>Start Draw</h6>

        <div class="mb-1">
          <label for="formGroupExampleInput" class="form-label">Raffle Name</label>
          <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Draw Name" onChange={(e) => { setDrawName(e.target.value) }} />
        </div>
      

        <button className='btn btn-primary' onClick={() => createDraw()}>Start</button>


        
          

            <div className="row">

                <div className='col-sm-2 bgit2'><span>{data?.result?.ticketNo}</span></div></div>
        
        

      </div>


    </div>
  )
}

export default StartDraw